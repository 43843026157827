var items = [
  { 
    title:"Social Network",
    subtitle:"(used name: 'Akcicka' - called it 'Community network')",
    description:"Very similar to today's Facebook, designed to group people/friends - and aimed to enable them to create events - and do follow up as discussion amongst the event memebers/friends, publish event photos, etc.",
    year:1997,
    real:2003
  },
  
  { title: "DBS - Dynamic Brake-light System",
    description:"This innovation brings significant improvement of security and predictability of other driver’s behaviour and intentions – even, or better say mainly in bad weather conditions.",
    year: 2001
  },

  {title:"Apple", description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ab repudiandae, explicabo voluptate et hic cum ratione a. Officia delectus illum perferendis maiores quia molestias vitae fugiat aspernatur alias corporis?"},
  {title:"Apple", description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ab repudiandae, explicabo voluptate et hic cum ratione a. Officia delectus illum perferendis maiores quia molestias vitae fugiat aspernatur alias corporis?"},
  {title:"Apple", description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ab repudiandae, explicabo voluptate et hic cum ratione a. Officia delectus illum perferendis maiores quia molestias vitae fugiat aspernatur alias corporis?"},
];
  
export default items;