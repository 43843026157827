// import './App.css';
import Item from './Item'
import items from './data.js'

function App() {
  var arr=[]; for(var i=1; i<7; i++) arr.push(i);

  return (
    <div className="background">

      {/* ...just for the background bubbles... */}
      <div className="dots">
        { arr.map((item, index) => <span key={index}></span>) }
      </div>

      <div className="content">
        <h1>Visionary Ideas</h1>

        <div className="card-wrapper">
          { items.map((item, index) => <Item key={index} item={item} /> )}
        </div>
      </div>

    </div>
  );
}

export default App;
