
function Item({item}) {
  return (
      <div className="card">

        <div className="face face1">
          <div className="text-box">
            <h3>{item.title}</h3>
          </div>
        </div>

        <div className="face face2">
          <div className="text-box">
            <div className="text">{item.description}</div>
          </div>
        </div>

        <div className="face face3">
          <div className={"text-box " + (item.real?"done":"open")}>
            {item.real?
            <span className="done">Realized {item.real}</span>:
            <span className="open">Not yet applied...</span>
            }
          </div>
        </div>

    </div>
            
  );
}

export default Item;
